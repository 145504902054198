/* eslint-disable no-param-reassign */
export default {
  /**
  * Install the plugin
  */
  install(Vue, options) {
    Vue.prototype.$auroraServer = {
      maintenance: options.maintenance,
    };
  },
};
